@use 'colors';

$branding-image-height: 520px;

html, body {
  background-color: colors.$blue400 !important;
}

.help-block.help-partner {
  margin-bottom: 1.25em;
}

.login-container-background {
  background-size: cover;
  min-height: 100vh;
  z-index: 1;
}

.login-container {
  height: 100%;
  text-align: center;
}

// Card that floats in the center
.login-card {
  background-color: colors.$gray200;
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(17, 25, 31, 0.24), 0 0 8px 0 rgba(17, 25, 31, 0.12);
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin-top: 30px;

  // Overrides bootstrap, can remove/refactor once form styles are cleaned up
  input {
    border: 1px solid colors.$gray400;
    border-radius: 5px;
    box-shadow: none;
    box-sizing: border-box;
    color: colors.$gray500;
    font-size: 16px;
    height: 52px;
    line-height: 52px;
    margin-bottom: 15px;
    padding: 0 14px;
    width: 100%;

    &:focus {
      border: 1px solid colors.$blue300;
      box-shadow: none;
      color: black;
      outline: none;
    }
  }
}

.login-card-content {
  border-radius: 6px;
  display: inline-block;
  min-height: $branding-image-height;
  max-width: 320px;
  padding: 0 53px 40px 53px;
  text-align: left;
  vertical-align: top;
}

.login-logo {
  height: 60px;
  margin-top: 78px;
}

.invite-confirm-form {
  p {
    color: colors.$gray500;
    margin-bottom: 17px;
  }

  input {
    font-size: 14px;
    padding: 11px;
  }

  .mismatch-message {
    color: red;
    display: none;
  }

  &.weak-password {
    .invite-confirm-validation-message {
      color: red;
    }

    .password-input-1, {
      border: 1px solid red;
    }
  }

  &.password-mismatch {
    .mismatch-message {
      display: inline-block;
    }

    .password-input-1,
    .password-input-2 {
      border: 1px solid red;
    }
  }
}

@media (max-width: 480px) {
  .login-card {
    margin-top: 20px;
  }
}

@media (min-width: 480px) {
  .login-container {
    &::before,
    &::after {
      content: '';
      display: inline-block;
      font-size: 1px;
      height: 100%;
      vertical-align: middle;
      width: 1px;
    }
  }
}

@media (max-width: 768px) {
  .login-card-cobranding {
    display: none;
  }

  .login-card-cobranding-mobile {
    display: block;
    width: 460px;
  }

  .login-container-cobranded-mobile {
    .login-card {
      max-height: 625px;
    }

    .login-card-content {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0 53px;
      width: 354px;
    }

    .login-logo {
      height: 66px;
    }
  }
}

@media (min-width: 768px) {
  .login-card-content {
    width: 320px;
  }

  .login-container-cobranded {
    .login-card-content {
      border-bottom-left-radius: 0;
      border-left: none;
      border-top-left-radius: 0;
      padding: 0 53px;
    }

    .login-logo {
      height: 60px;
    }
  }

  .login-card-cobranding {
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
    display: inline-block;
    width: 230px;
    margin-left: -1px;
  }

  .login-card-cobranding-mobile {
    display: none;
  }
}

.login-card-cobranding img {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  box-sizing: border-box;
  height: $branding-image-height;
  width: 230px;
}

.login-card-cobranding-mobile img {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  box-sizing: border-box;
  height: 115px;
  width: 460px;
}

// reset bootstrap default
.form-login {
  margin: 0;
}

// because our bootstrap is too old to have this
.btn-block {
  display: block;
  width: 100%;
}

// just for the login page, for now.
// TO DO: Remove once we have global new buttons
.btn-login {
  background-color: #8cc349;
  background-image: none !important;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 16px;
  line-height: 1.618;
  text-shadow: none !important;
}

.btn-login:hover,
.btn-login:active,
.btn-login:focus {
  background-color: #6ba819;
  background-image: none !important;
  border: none;
  color: white;
  text-shadow: none !important;
}

.login-logo {
  min-width: 103%; // looks weird but needed to ensure copyright logo offset a few px to the right
  padding-bottom: 15px;
}
.login-logo-ours {
  height: 120px;
}

.login-error-text {
  color: colors.$red300;
  font-size: 14px;
  padding-bottom: 15px;
}

.login-message-text {
  color: colors.$gray500;
  font-size: 14px;
  padding-bottom: 15px;
}

.login-link {
  color: colors.$gray600;
  font-size: 14px;
}

.login-link:hover,
.login-link:active {
  color: colors.$blue300;
}

.footer-login {
  padding: 30px;
  text-align: center;
  z-index: 3;
}

.footer-login-link {
  color: colors.$gray100;
  font-size: 14px;
  font-weight: 400;
}

.footer-login-link:hover {
  color: #fff;
}

.footer-login-divider {
  color: rgba(247, 247, 247, 0.7);
  font-size: 11px;
  font-weight: 400;
  padding-left: 3px;
  padding-right: 3px;
}

.stay {
  margin-bottom: 30px;

  label {
    cursor: pointer;
    color: #000;
    font-weight: normal;
  }
  input {
    position:absolute;
    left: -999px;
    width: 0;
  }
  input:focus + label:before{
    border-color: colors.$blue300;
  }
  label:hover + label:before{
    box-shadow:
      inset 0 0 2px 0 rgba(17, 25, 31, 0.12),
      inset 0 2px 2px 0 rgba(17, 25, 31, 0.24);
  }
  label:before{
    background: colors.$white;
    border-color: colors.$gray400;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    content: '';
    cursor: pointer;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    width: 18px;
    margin-right: 5px;
    margin-top: -1px;
  }
  input:checked + label:before{
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIycHgiIGhlaWdodD0iMjJweCIgdmlld0JveD0iMCAwIDIyIDIyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zICgxMTk3MCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQgMSBDb3B5IDExPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkLTEtQ29weS0xMSIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgc3Ryb2tlPSIjOEJDNTNGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik03LDExIEwxMCwxNCBMMTUsOCIgaWQ9IkltcG9ydGVkLUxheWVycyIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
    background-position: center;
    background-repeat: no-repeat;
  }
}
